import { i18n } from "./i18n";
import { setDefaultYupTranslations } from "./setDefaultYupTranslations";

export const DEFAULT_LANGUAGE_KEY = "en";

export const SUPPORTED_LANGUAGES = [
  {
    key: DEFAULT_LANGUAGE_KEY,
    value: "English",
  },
  {
    key: "sv-SE",
    value: "Svenska (Sverige)",
  },
  {
    key: "de-DE",
    value: "Deutsch (Deutschland)",
  },
  {
    key: "fr-CH",
    value: "Français (Suisse)",
  },
  {
    key: "it-CH",
    value: "Italiano (Svizzera)",
  },
  {
    key: "ja-JP",
    value: "日本語 (日本)",
  },
] as const;

export const supportedLanguages = Object.values(SUPPORTED_LANGUAGES).map(
  ({ key }) => key,
);

export type SupportedLanguage = (typeof supportedLanguages)[number];

interface LanguageContent {
  readonly [key: string]: string | LanguageContent;
}

/**
 * Lazy loads the language files so
 * they end up in a separate file (chunk)
 * and only one file (chunk) will be loaded
 * depending on the selected language
 */
const loadLocaleResources = async (
  langKey: SupportedLanguage,
): Promise<LanguageContent> => {
  const res = await fetch(`/locales/${langKey}.json?url&t=${Date.now()}`);
  return res.json();
};

export const getSupportedLanguage = (value: string): SupportedLanguage => {
  if (!supportedLanguages.includes(value as SupportedLanguage)) {
    return DEFAULT_LANGUAGE_KEY;
  }

  return value as SupportedLanguage;
};

export const addLanguageBundles = async (
  lang: SupportedLanguage,
): Promise<void> => {
  const langResources = await loadLocaleResources(lang);

  i18n.addResourceBundle(lang, "common", langResources, true);
  await i18n.changeLanguage(lang);
  setDefaultYupTranslations();
};
